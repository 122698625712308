.tile{
    max-width: 100%;
}

.title{
    padding-bottom: 0 !important;
    font-size: 2rem !important;
    text-align: center;
    overflow-wrap: anywhere;
}

.subtitle{
    padding: 0 !important;
    font-size: 1rem !important;
    text-align: center;
}

.pill{
    background-color: #d8d8d865 !important; 
    border-radius: 20px;
    font-size: 0.75rem;
    margin-right: 5px;
    color: #41465C !important;
    font-weight: bold !important;
    display:inline-flex;
    margin-top:5px;
}

.pills-container{
    text-align: center;
    margin-top:12px !important;
    margin-bottom:24px !important;
}

.skill-tile{
    width: 100%;
    height: auto;
    border-radius:20px;
}

.hidden{
    display: none !important;
}

.image-scroller{
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

.gallery-image{
    width: 40%;
    height: auto;
    transition: transform .3s;

    object-fit: cover;
    max-height: 25vh;

    border-radius: 20px;
}

.gallery-image:hover {
    -ms-transform: scale(1.07); /* IE 9 */
    -webkit-transform: scale(1.07); /* Safari 3-8 */
    transform: scale(1.07); 
}

.pswp img {
    max-width: none;
    object-fit: contain;
}