.skill-image{
    border-radius: 20px;
    width: 100%;
    height: auto;
    transition: transform .3s;
}

.skill-image:hover {
    -ms-transform: scale(1.07); /* IE 9 */
    -webkit-transform: scale(1.07); /* Safari 3-8 */
    transform: scale(1.07); 
}