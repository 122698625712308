.Section {
  display: flex;
  height: 100vh;
  margin: 0;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Home-background {
  width: 100%;
  height: 100%;

  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  /* background-image: url('./../assets/images/main-background.png'); */
}

.Home-Hi-text {
  text-align: center;
}

.Home-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;

  /* object-position: fixed; */
}

/* Scroller icon */
.Home-icon-scroll, .Home-icon-scroll:before{
  position: absolute;
  left: 50%;
}

.Home-icon-scroll {
  bottom:10%;

  width: 40px;
  height: 70px;
  margin-left: -20px;
  margin-top: -35px;
  box-shadow: inset 0 0 0 4px #fff;
  border-radius: 25px;
}
  
.Home-icon-scroll:before {
  content: '';
  width: 10px;
  height: 10px;
  background: #fff;
  margin-left: -5px;
  top: 10px;
  border-radius: 4px;
  animation-duration: 1.7s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}
  
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}

.particles {
    z-index: -1;
}