.flashcards-logo{
    height: 100px;
    height: 100px;
    border-radius: 25px;
}

.flashcards-title{
    font-weight: bold;
}

.by-p{
    padding-top: 0 !important;
}

.flashcards-description-p{
    font-weight: 600;
}

.g-play{
    img{
        max-width: 80%;
    }
}

.g-play-col{
    align-self: center;
}

.g-play-qr{
    max-width: 70%;
}


.flashcards-screen{
    max-width: 100%;
    max-height: 60vh;
}

.flashcards-screen-col{
    text-align: center;
    align-self: center;
}

@media only screen and (max-width: 768px) {
    .flashcards-screen-col{
        display: none;
    }
}

