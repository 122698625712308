@media only screen and (max-width: 600px) {
    .navBar{
        display: none;
    }

    .logoDiv {
        display: none;
    }

    .r-logo-image{
        display: none;
    }

    .Home-Hi-text h1 {
        margin: 0;
        font-size:  15vw;
        font-weight: bold;
    }
      
    .Home-Hi-text h2 {
        margin: 0;
        font-size: 8vw;
        font-weight: bold;
    }
}

@media only screen and (min-width: 600px) {
    .navBar{
        position: absolute;
        top:32px;
        right:32px;
        left:32px;
        z-index: 1;
        padding-left: 10px;
        padding-right: 30px;
        height: 70px;
        display: flex;
    }

    .logoDiv {
        padding: 10px;
    }
    
    .r-logo-image{
        height: 100%;
        border-radius: 100px;
        transition: transform .3s;
    }
    
    .r-logo-image:hover {
        -ms-transform: scale(1.07); /* IE 9 */
        -webkit-transform: scale(1.07); /* Safari 3-8 */
        transform: scale(1.07); 
    }

    .Home-Hi-text h1 {
        margin: 0;
        font-size:  15vh;
        font-weight: bold;
    }
      
    .Home-Hi-text h2 {
        margin: 0;
        font-size: 8vh;
        font-weight: bold;
    }
}

.linksDiv{
    align-self: center;
    margin-left: auto;

}

.navLink {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    position: relative;
    margin: 25px;
}

.navLink::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 4px;
    background-color: #18272F;
    bottom: -5px;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

.navLink:hover{
    color: #FF5757 !important;
}

.navLink:hover::before {
    bottom: -5px;
    transform-origin: left;
    transform: scaleX(1);
}