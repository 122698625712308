.black {
    color: #000000;
}

.dark{
    color:#4C4E52;
}

.light{
    color: #FF5757;
}

.white-background{
    background-color:white;
}

.light-background{
    background-color:#FF5757;
}

.dark-background{
    background-color:#4C4E52;
}

.white{
    color: #ffffff;
}

.page-padding-first{
    padding-top: 6vw;
    padding-left: 6vw;
    padding-right: 6vw;
}

.page-padding-end{
    padding-bottom: 6vw;
    padding-left: 6vw;
    padding-right: 6vw;
}

.page-padding-sides{
    padding-right: 6vw;
    padding-left: 6vw;
}

@media (min-width: 1030px) {
    .page-padding{
        padding-left:18vw;
        padding-right:18vw;
        padding-top:6vw;
        padding-bottom:6vw;
    }
}
@media (max-width: 1030px) {
    .page-padding{
        padding:2vw;
    }
}

.padding-5{
    padding: 1vw;
}

.padding-5-bottom{
    padding-bottom: 1vw;
}

.icon-margin{
    margin: 0.5vw;
}

.padding-1-top{
    padding-top: 0.5vw;
}

.padding-0-top{
    padding-top: 0 !important;
}

.padding-1-bottom{
    padding-bottom: 0.5vw;
}

.padding-5-left-bottom{
    padding-left: 1vw;
    padding-bottom: 1vw;
}

.padding-10{
    padding: 3vw;
}

.padding-10-bottom{
    padding-bottom: 3vw;
}

.padding-10-top-bottom{
    padding-top: 3vw;
    padding-bottom: 3vw;
}

.padding-5-top{
    padding-top: 1vw;
}

.padding-10-top{
    padding-top: 3vw;
}

.padding-20{
    padding: 6vw;
}

.padding-20-top{
    padding-top: 6vw;
}

.padding-40-top{
    padding-top: 12vw;
}

.padding-20-sides{
    padding-right: 10vw !important;
    padding-left: 10vw !important;
}

.padding-1-sides{
    padding-right: 2vw;
    padding-left: 2vw;
}

.margin-10{
    margin: 3vw;
}

.margin-10-bottom{
    margin-bottom: 3vw;
}

.margin-10-top{
    margin-top: 3vw;
}

.margin-5-right{
    margin-right: 1.5vw;
}

.no-padding-margin{
    padding: 0 !important;
    margin: 0 !important;
}

.no-margin{
    margin:0 !important;
}

.no-margin-bottom{
    margin-bottom:0 !important;
}

.no-padding{
    padding:0 !important;
}

.title-h1{
    font-size: 4rem !important;
    margin-bottom: 0 !important;
    font-weight: bold;
}

.title-p{
    margin-bottom: 0;
    font-weight: bold;
}

.center-text{
    text-align: center;
}

.right-text{
    text-align: right;
}

.line{
    border-style: dotted;
}

/* #downDarkTriangle {
    width: 100%;
    height: 0;
    border-bottom: 6vh solid #41465C;
    border-left: 100vw solid transparent;
}

#downWhiteTriangle {
    position: absolute;
    bottom: 0;
    width: 10%;
    height: 0;
    z-index: 3;
    border-bottom: 6vh solid #ffff;
    border-right: 100vw solid transparent;
}

#upDarkTriangle {
    width: 100%;
    height: 0;
    border-top: 6vh solid #41465C;
    border-right: 100vw solid transparent;
} */

/* Flashcards css */
.flashcards-yellow{
    color:#FED032;
}

.inner-link{
    color: #FF5757;
}