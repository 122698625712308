.about-title-div{
  text-align: center;
}

.me-image{
  border-radius: 100vh;
  width: 90%;
  height: auto;
}

.me-image-center{
  text-align: center !important;
}

#about{
    margin-bottom: 0px;
}