/* #google-doc-container{
    position: absolute;
    background-color: #eee;
    left:   16px;
    top:    16px;
    right:  16px;
    bottom: 16px; */
    .google-doc {
        position: absolute;
        width: 100%;
        height: 1000px;
        background-color: #fff;
        transform-origin: top left;
        transition: all 100ms ease-in-out !important;
        box-shadow: 0px 6px 15px 0px rgba(0,0,0,0.32);
        // &:after {
        //     position: absolute;
        //     bottom: 0;
        //     width: 100%;
        //     height: 256px;
        //     background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 93%,rgba(255,255,255,1) 100%);
        //     content: "";
        // }
    }
    .cv-iframe{
       border: 0;
       width: 100%;
       height: 1000px;
       box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.32);

   }
/* } */